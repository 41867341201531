/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');
require('./adminlte');
require('./chart');
require('./dropzoneForm');
require('./calculateSize');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/RunnerJobEdit');
require('./components/OrderTable');
require('./components/OrderStateQuickChange');
require('./components/SelectCustomer');
require('./components/AddItem');
