import React from 'react';

function NextOrderStates(props) {
    const { onClick, order, canReopenOrder, customerService } = props;

    const nextStates = (order) => {
        let states;
        if (customerService == true) {
            if (order.state == "App\\State\\Order\\Completed") {
                states = (
                    <div
                        className="btn btn-xs btn-success btn-block"
                        onClick={() => onClick(order.id, "App\\State\\Order\\Draft")}
                    >
                        Re-Open Order
                    </div>
                );
            }
        }
        else {
            switch (order.state) {
                case "App\\State\\Order\\Draft":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\PendingPickupSchedule")}
                            >
                                Pending Pickup Schedule
                            </button>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\WarehouseReceived")}
                            >
                                Customer Drop Off In Warehouse
                            </button>
                        </div>
                    )
                    break;
                case "App\\State\\Order\\PickupScheduled":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\Collected")}
                            >
                                Pickup Completed
                            </button>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\PendingPickupSchedule")}
                            >
                                Incomplete Pickup
                            </button>
                        </div>
                    )
                    break;
                case "App\\State\\Order\\Collected":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\WarehouseReceived")}
                            >
                                Received Warehouse
                            </button>
                        </div>
                    )
                    break;
                case "App\\State\\Order\\WarehouseReceived":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\VendorCollected")}
                            >
                                Vendor Collected
                            </button>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\InHouseCleaning")}
                            >
                                InHouse Cleaning
                            </button>
                        </div>
                    );
                    break;
                case "App\\State\\Order\\VendorCollected":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\PendingDeliverySchedule")}
                            >
                                Cleaning Completed
                            </button>
                        </div>
                    );
                    break;
                case "App\\State\\Order\\InHouseCleaning":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\PendingDeliverySchedule")}
                            >
                                Cleaning Completed
                            </button>
                        </div>
                    );
                    break;
                case "App\\State\\Order\\PendingDeliverySchedule":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\Returned")}
                            >
                                Customer Self Collect
                            </button>
                        </div>
                    );
                    break;
                case "App\\State\\Order\\ReturnScheduled":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\InDelivery")}
                            >
                                Runner Collected
                            </button>
                        </div>
                    );
                    break;
                case "App\\State\\Order\\InDelivery":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\Returned")}
                            >
                                Delivered To Customer
                            </button>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\PendingDeliverySchedule")}
                            >
                                Incomplete Delivery
                            </button>
                        </div>
                    );
                    break;
                case canReopenOrder && "App\\State\\Order\\Returned":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\Draft")}
                            >
                                Reprocessing
                            </button>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\Completed")}
                            >
                                Delivery Completed
                            </button>
                        </div>
                    );
                    break;
                case "App\\State\\Order\\Returned":
                    states = (
                        <div className='mb-1'>
                            <button
                                className="btn btn-xs btn-success btn-block"
                                onClick={() => onClick(order.id, "App\\State\\Order\\Completed")}
                            >
                                Delivery Completed
                            </button>
                        </div>
                    );
                    break;
                default:
                    break;
            }
        }
        return states;
    }

    return (
        <div>
            {nextStates(order)}
        </div>
    );
}

export default NextOrderStates;

