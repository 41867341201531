import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import NextOrderStates from './NextOrderStates';
import { dateFormatter, myDateTime, humaniseOrderState, orderAddress } from '../helpers.js';
window.humaniseOrderState = humaniseOrderState;
window.orderAddress = orderAddress;

function OrderTable(props) {
    const { proporders, internal, canreopenorder, cancreateorder, customerservice } = props;
    const [orders, setOrders] = useState(proporders.data);

    const changeRunnerJobstate = (orderId, state) => {
        axios.post(`/order/status/${orderId}`, { state })
            .then(function (response) {
                let orderList = orders.map((order) => {
                    let orderObj = order;
                    if (order.id == response.data.id) {
                        orderObj = response.data
                    }

                    return orderObj;
                })

                setOrders(orderList);
            })
            .catch(function (error) {

            });
    }
    const styleObj = {
        fontSize: 14,
        color: "#2f2f2f",
    }
    return (
        <table className="table table-bordered table-striped" style={styleObj}>
            <tbody>
                <tr>
                    <th width='5%'>Booking P&D Id</th>
                    <th width='13%'>Customer</th>
                    <th>Address</th>
                    <th width='17%'>Date Time</th>
                    <th>Status</th>
                    <th>Notis Ambilan </th>
                    <th>Created at</th>
                    <th className="text-wrap" width='17%'>Action</th>
                </tr>
                {orders.map((order, index) => (
                    <tr key={order.id}>
                        <td>
                            {order.woocommerce_order_id ? <span className="badge badge-primary">ECOMM</span> : ''}
                            <a href={internal ? `/external/order/${order.id}` : `/order/${order.id}`}>{order.id}</a>
                        </td>
                        <td>
                            <div className="row d-flex justify-content-center text-center">
                                {order.customer.name}
                            </div>
                            <div className=" row d-flex justify-content-center text-center">
                                {order.customer.phone_no && (
                                    <React.Fragment>
                                        <div>{order.customer.phone_no}<a href={`https://api.whatsapp.com/send?phone=${order.customer.phone_no}`} target='blank'><i className="fab fa-whatsapp icon-green"></i></a>
                                            <a href={'tel:' + order.customer.phone_no} target='blank'><i className="fas fa-phone icon-phone"></i></a></div>
                                    </React.Fragment>
                                )}
                                {order.comments ? order.comments.map((orderComment, i) => (
                                    i == (order.comments.length - 1) ?
                                        <i key={orderComment.id} className="far fa-comment-alt icon-green" data-container="body" data-toggle="popover" data-placement="left" data-content={orderComment.comment}></i> : ''
                                )) : ''}
                            </div>
                        </td>
                        <td>
                            {orderAddress(order) !== null && (
                                <React.Fragment>
                                    {(orderAddress(order))}
                                    <a href={`http://maps.google.com/maps?q=${encodeURI(orderAddress(order))}`} target='blank'><i className="fas fa-map-marked-alt icon-blue"></i></a>
                                </React.Fragment>
                            )}
                        </td>
                        <td>{<React.Fragment> <span className="badge badge-primary">Preferred</span><br></br>
                            {dateFormatter(order.prefered_pickup_datetime)} <br></br>
                            {order.collected_at ? <div><span className="badge badge-info">Collected </span><br></br>
                            {dateFormatter(order.collected_at)}</div> : ''}
                            {order.returned_at ? <div><span className="badge badge-success">Received </span><br></br>
                            {dateFormatter(order.returned_at)}</div> : ''}
                        </React.Fragment>}
                        </td>
                        <td>{humaniseOrderState(order.state)}</td>

                        <td>{<React.Fragment> {order.notice_ambilan_ref}<br></br>
                            {order.no_need_invoice == true ? <a><button type="button" className="btn sticky-top btn-block btn-outline-danger btn-xs">No Invoice Needed</button></a> : ''}
                            {order.invoice ? <a href={`/order/invoice/${order.invoice.id}`}><button type="button" className="btn sticky-top btn-block btn-outline-info btn-sm">{order.invoice_no}</button></a> : ''}
                        </React.Fragment>}
                        </td>
                        <td>{myDateTime(order.created_at)}</td>
                        <td>{internal ? null : cancreateorder == true ? <NextOrderStates order={order} canReopenOrder={canreopenorder} onClick={changeRunnerJobstate} /> : customerservice == true ? <NextOrderStates order={order} canReopenOrder={canreopenorder} customerService={customerservice} onClick={changeRunnerJobstate} /> : null}
                            {internal ? <a href={`/external/order/${order.id}`}><button className='btn btn-block btn-xs btn-primary mr-2 '>View </button></a> :
                                <a href={`/order/${order.id}`}><button className='btn btn-block btn-xs btn-primary mr-2'>View </button></a>}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default OrderTable;
const element = document.getElementById("OrderTable");
if (element) {
    let props = Object.assign({}, element.dataset);
    Object.keys(props).map(key => props[key] = JSON.parse(props[key]));
    ReactDOM.render(<OrderTable {...props} />, element);
}
